/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { PhoneFilled, EnvironmentFilled } from "@ant-design/icons";
import { Row, Col, Anchor, BackTop } from "antd";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import CSCBackTop from "../../widget/CSCBackTop";
import owRequest from "../../libs/request";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "1200px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            locationLeftList: [],
            locationRightList: [],
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }

        let url = window.location.href;
        if (url.includes("#contactus")) {
            setTimeout(() => {
                this.onClickTabItem(3, "contactus");
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        this.getOfficeLocation();
    }

    // 公司介绍
    getCompanyIntro() {
        return (
            <div style={{ ...textSty, marginTop: "20px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                        marginTop: "60px",
                        alignItems: "center",
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <img
                            src="https://img1.baidu.com/it/u=2279114287,3831837115&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=333"
                            alt=""
                            style={{
                                paddingRight: "10px",
                                width: "100%",
                                height: "100%",
                                marginBottom: "20px",
                            }}
                        />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textIndent: this.state.isMobile ? "15px" : "30px",
                            paddingLeft: this.state.isMobile ? "0" : "30px",
                            fontSize: "18px",
                            lineHeight: "33px",
                        }}
                    >
                        超云是由CEC中国电子、云基地产业基金联合注资成立的国家高新技术企业，公司专注于云基础架构、云系统、云服务三大产业布局，现已形成了覆盖X86服务器、信创整机、超融合、分布式存储等产品领域的完善业务体系，是国内专业的数据中心产品及服务提供商。
                        公司成立至今，在多个区域建立了研发、生产制造及技术支持中心，形成了覆盖全国的研发、营销、服务体系。
                        超云始终坚持自主创新、产业融合的发展路线，与高等研究机构建立了紧密的产学研合作关系，国家级重大研究课题；深度整合产业链上下游资源，满足不同行业的创新应用需求。
                        作为拥有自主知识产权的民族企业，超云将在打造技术领先、安全可靠的国产品牌道路上砥砺前行，共同建设美好数字世界！
                    </div>
                </div>
            </div>
        );
    }

    // 使命愿景
    getMissionVision() {
        const wrapperSty = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            position: "relative",
            marginBottom: !this.state.isMobile ? "0" : "50px",
        };
        const missionSty = {
            width: "13rem",
            height: "16rem",
            background: "#46a670",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            fontSize: "14px",
            padding: "15px",
            zIndex: 2,
            color: "#fff",
        };
        const titleSty = {
            fontSize: "20px",
            fontWeight: 600,
            marginTop: "40px",
            marginBottom: "30px",
            color: "#fff",
        };
        const backSty = {
            position: "absolute",
            zIndex: 1,
            right: "45px",
            top: "-20px",
            width: "13rem",
            height: "16rem",
            background: "#c8ccca",
        };

        return (
            <div
                style={{ ...textSty, marginTop: "6rem", marginBottom: "20px" }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                    }}
                >
                    <div style={wrapperSty}>
                        {!this.state.isMobile ? (
                            <div style={backSty}></div>
                        ) : null}

                        <div style={missionSty}>
                            <div style={titleSty}>使命</div>
                            <div>提升客户业务价值</div>
                            <div>共建美好数字世界</div>
                        </div>
                    </div>
                    <div style={wrapperSty}>
                        {/* <div style={backSty}></div> */}
                        {!this.state.isMobile ? (
                            <div style={backSty}></div>
                        ) : null}
                        <div style={missionSty}>
                            <div style={titleSty}>愿景</div>
                            <div>
                                成为用户最信赖的数据中心产品与服务提供商
                                {/* 成为最值得用户信赖的智能云计算基础设施提供商 */}
                            </div>
                        </div>
                    </div>
                    <div style={wrapperSty}>
                        {!this.state.isMobile ? (
                            <div style={backSty}></div>
                        ) : null}
                        <div style={missionSty}>
                            <div style={titleSty}>价值主张</div>
                            <div>让数据中心更简单</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    getOfficeLocation() {
        // let url = "";
        // if (this.state.searchTxt !== "") {
        //     url = `/office-website/recruit?recruit_type=${this.rCode}&job_title=${this.state.searchTxt}`;
        // } else {
        //     url = `/office-website/recruit?recruit_type=${this.rCode}`;
        // }
        owRequest
            .get(`/office-website/office-location`)
            .then((res) => {
                if (res && res.code == 2000) {
                    let ret = [];
                    ret = res.data.rows;
                    let locationLeftList = [];
                    let locationRightList = [];
                    for (let i = 0; i < ret.length; i++) {
                        let item = ret[i];
                        if (i % 2 == 0) {
                            locationLeftList.push(item);
                        } else {
                            locationRightList.push(item);
                        }
                    }

                    this.setState({
                        locationLeftList,
                        locationRightList,
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    getContactItem(item) {
        return (
            <div style={{ fontSize: "14px", marginBottom: "10px" }}>
                <div style={{ marginBottom: "7px" }}>
                    <EnvironmentFilled style={{ marginRight: "8px", color:"#00AA71"  }} />
                    {item.province} - {item.detailed_address}
                </div>
                <div style={{ marginBottom: "7px" }}>
                    <PhoneFilled style={{ marginRight: "8px", color:"#00AA71"  }} />
                    {item.contact_tel}
                </div>
            </div>
        );
    }

    // 特别说明
    getContactUs() {
        return (
            <div style={{ ...textSty, marginTop: "70px", maxWidth: "980px" }}>
                <div
                    style={{
                        marginTop: "10px",
                        marginBottom: "20px",
                        fontSize: "18px",
                        fontWeight: 600,
                    }}
                >
                    超云数字技术集团有限公司
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontSize: "15px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                    }}
                >
                    <div>
                        <EnvironmentFilled style={{ marginRight: "8px", color:"#00AA71" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            超云总部 -
                            北京市昌平区鲁疃路5号中国电子信息安全技术研发基地A座4层
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            研发中心 -
                            北京市经济技术开发区科创十四街6号院2号楼3层
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontSize: "15px",
                    }}
                >
                    <PhoneFilled style={{ marginRight: "8px",  color:"#00AA71"  }} />
                    4006-330-360
                </div>
                <div
                    style={{
                        marginTop: "40px",
                        marginBottom: "20px",
                        fontSize: "18px",
                        fontWeight: 600,
                    }}
                >
                    超云全国各地办事处
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            // marginRight: "14px",
                            marginRight: !this.state.isMobile ? "50px" : "0px",
                        }}
                    >
                        {this.state.locationLeftList.map((item) => {
                            return this.getContactItem(item);
                        })}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            alignItems: "flex-start",
                            marginLeft: !this.state.isMobile ? "10px" : "0",
                        }}
                    >
                        {this.state.locationRightList.map((item) => {
                            return this.getContactItem(item);
                        })}
                    </div>
                </div>
            </div>
        );
    }

    getTitleDes() {
        return (
            <div style={{ ...textSty, textIndent: isMobile ? "20px" : "40px" }}>
                超云数字技术集团有限公司（下简称“超云”）通过统一的管理、统一的培训、统一的服务标准、统一的规范和统一的考核，使得客户可以享受到周到、完美、技术专业、形象亲和的超云售后服务。
            </div>
        );
    }

    getDevelopmentHistory() {
        const wrapperSty = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
        };
        const missionSty = {
            width: this.state.isMobile ? "18rem" : "13rem",
            marginBottom: this.state.isMobile ? "2rem" : "1rem",
            // height: "17rem",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            fontSize: "14px",
            padding: "20px 15px",
            color: "#333",
            position: "relative",
        };
        const yearSty = {
            fontSize: "16px",
            // marginTop: "10px",
            marginBottom: "10px",
            textAlign: "start",
        };
        const dhTitleSty = {
            fontSize: "19px",
            fontWeight: 600,
            marginTop: "0px",
            marginBottom: "15px",
            textAlign: "start",
        };
        const redDotSty = {
            // background: "#e1251a",
            background: "#46a670",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            position: "absolute",
            top: "-11px",
            left: !this.state.isMobile ? "0%" : "0%",
            marginLeft: "10px",
        };
        const descTxtSty = {
            fontSize: "16px",
            color: "#999",
            // textAlign: "start",
        };
        const history_1 = [
            {
                year: "2010",
                title: "超云成立",
                desc: "由云基地产业基金、中关村发展集团联合注资成立，旨在打造全球领先的云计算企业群落",
            },
            {
                year: "2011",
                title: "产品发布",
                desc: "25款超云服务器全线问世",
            },
            {
                year: "2012",
                title: "产品创新",
                desc: "发布业界首创高温节能服务器，开创服务器产品新纪元",
            },
            {
                year: "2013",
                title: `"HERO'理念`,
                desc: "发布HERO（高密度、低能耗、易管理、定制化）业务战略，业内首款耐高温节能服务器完成研发与下线生产",
            },
        ];
        const history_2 = [
            {
                year: "2014",
                title: "行业认可",
                desc: "取得“德勤高科技高成长企业10强”荣誉",
            },
            {
                year: "2015",
                title: "企业认证",
                desc: "荣获“国家高新技术企业”，参与863等国家级科研项目",
            },
            {
                year: "2016",
                title: "价值主张",
                desc: "提出“让数据中心更简单”价值主张，发布数据中心“PACE”解决方案理念",
            },
            {
                year: "2017",
                title: "新型数据中心问世",
                desc: "构建融合架构数据中心体系，向DC4.0时代迸发",
            },
        ];
        const history_3 = [
            {
                year: "2018",
                title: "开启新篇章",
                desc: "获得中国电子旗下中国长城集团注资，助力国家网信产业发展",
            },
            {
                year: "2019",
                title: `"EDC"架构`,
                desc: "打造“EDC”融合架构数据中心业务战略",
            },
            {
                year: "2020",
                title: "品牌升级",
                desc: "依托优势产业布局，打造智能云计算基础设施领导品牌",
            },
            {
                year: "2021",
                title: "南京工厂建成",
                desc: "超云全新南京工厂建成，激发数字经济发展强劲动力，强化生产制造能力，比肩业界先进水平",
            },
        ];
        const history_4 = [
            {
                year: "2022",
                title: "飞速发展",
                desc: "承担国家疫情防控大数据平台任务，紧随国家“东数西算”与“双碳”发展战略，“超云宁夏算力中心”落地，启动新一轮融资进程，加速实现新跨越，聚力领跑新时代",
            },
        ];
        return (
            <div
                style={{
                    ...textSty,
                    marginTop: "70px",
                    overflow: this.state.isMobile ? "scroll" : "hidden",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        justifyContent: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        width: "100%",
                        // flexDirection: "row",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                        paddingTop: "5px",
                        borderTop: !this.state.isMobile
                            ? "1px solid #ddd"
                            : "0",
                        marginBottom: "10px",
                    }}
                >
                    {history_1.map((item) => {
                        return (
                            <div style={wrapperSty}>
                                <div style={missionSty}>
                                    <div style={redDotSty}></div>

                                    <div style={yearSty}>{item.year}</div>
                                    <div style={dhTitleSty}>{item.title}</div>
                                    <div style={descTxtSty}>{item.desc}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        justifyContent: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        width: "100%",
                        // flexDirection: "row",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                        paddingTop: "5px",
                        borderTop: !this.state.isMobile
                            ? "1px solid #ddd"
                            : "0",
                        marginBottom: "10px",
                    }}
                >
                    {history_2.map((item) => {
                        return (
                            <div style={wrapperSty}>
                                <div style={missionSty}>
                                    <div style={redDotSty}></div>

                                    <div style={yearSty}>{item.year}</div>
                                    <div style={dhTitleSty}>{item.title}</div>
                                    <div style={descTxtSty}>{item.desc}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        justifyContent: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        width: "100%",
                        // flexDirection: "row",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                        paddingTop: "5px",
                        borderTop: !this.state.isMobile
                            ? "1px solid #ddd"
                            : "0",
                        marginBottom: "10px",
                    }}
                >
                    {history_3.map((item) => {
                        return (
                            <div style={wrapperSty}>
                                <div style={missionSty}>
                                    <div style={redDotSty}></div>

                                    <div style={yearSty}>{item.year}</div>
                                    <div style={dhTitleSty}>{item.title}</div>
                                    <div style={descTxtSty}>{item.desc}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        justifyContent: this.state.isMobile
                            ? "center"
                            : "flex-start",
                        width: "100%",
                        // flexDirection: "row",
                        flexDirection: !this.state.isMobile ? "row" : "column",
                        paddingTop: "5px",
                        borderTop: !this.state.isMobile
                            ? "1px solid #ddd"
                            : "0",
                        marginBottom: "10px",
                    }}
                >
                    {history_4.map((item) => {
                        return (
                            <div style={wrapperSty}>
                                <div style={missionSty}>
                                    <div style={redDotSty}></div>

                                    <div style={yearSty}>{item.year}</div>
                                    <div style={dhTitleSty}>{item.title}</div>
                                    <div style={descTxtSty}>{item.desc}</div>
                                </div>
                            </div>
                        );
                    })}
                    <div style={{ ...wrapperSty, height: 0 }}>
                        <div style={missionSty}></div>
                    </div>
                    <div style={{ ...wrapperSty, height: 0 }}>
                        <div style={missionSty}></div>
                    </div>
                    <div style={{ ...wrapperSty, height: 0 }}>
                        <div style={missionSty}></div>
                    </div>
                </div>
            </div>
        );
    }

    onClickTabItem(activeTab, id) {
        // console.log("activeTab", activeTab, id);
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log("anchorElement", anchorElement);
        if (anchorElement) {
            const yHeight = anchorElement.getBoundingClientRect().y;
            if (activeTab == 0) {
                window.scrollTo(0, 0);
            }

            // console.log("yHeight", yHeight);
            window.scrollTo({
                left: 0, // x坐标
                top: yHeight - 100, // y 坐标
                behavior: "smooth", // 可选值：smooth、instant、auto
            });
        }
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <img
                src={require("../../assets/head_company.png")}
                alt=""
                style={{ width: "100%" }}
            />,
            <div
                className="p_tab_box_51_wrapper"
                key={"p_tab_box_51_wrapper"}
                style={{
                    paddingTop: this.state.isMobile ? "30px" : "50px",
                    paddingBottom: this.state.isMobile ? "10px" : "50px",
                }}
            >
                <div className="p_tab_box_51_index1">
                    {[
                        { title: "公司介绍", href: "services_scope" },
                        { title: "使命愿景", href: "services_type" },
                        { title: "发展历程", href: "services_stander" },
                        { title: "联系我们", href: "contactus" },
                    ].map((item, index) => {
                        return (
                            <div
                                key={"cb" + index}
                                className={
                                    this.state.activeTab == index
                                        ? "p_tab_item_51_active p_tab_item_51"
                                        : "p_tab_item_51"
                                }
                                onClick={() => {
                                    this.onClickTabItem(index, item.href);
                                }}
                            >
                                {item.title}
                            </div>
                        );
                    })}
                </div>
            </div>,
            <div
                id="services_scope"
                key="services_scope"
                style={{
                    height: "auto",
                    width: "100%",
                    background: "#fafafa",
                    paddingBottom: "60px",
                    paddingTop: "2rem",
                }}
            >
                {/* <CSCTitle
                    title="公司介绍"
                    key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                {this.getCompanyIntro()}, */}
                <img     
                    src={require("../../assets/introduce_bg.png")}
                    alt=""
                    style={{ width: "100%" }}
                />
                {/* introduce_bg */}
            </div>,
            <div
                id="services_type"
                key="services_type"
                style={{
                    height: "auto",
                    width: "100%",
                    // background: "#fafafa",
                    // background:"rgb(255, 255, 255, 0.7)",
                    paddingBottom: "60px",
                    paddingTop: "4rem",
                }}
            >
                {/* <CSCTitle
                    title="使命愿景"
                    key="services_type"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        display: "inline-block",
                        borderBottom: "6px solid #2FB467",
                        paddingBottom:"0.6rem",
                        fontWeight: "700",
                    }}
                /> */}
                <img src={require("../../assets/mission_bg.png")} alt=""   style={{ width: "100%", paddingLeft:"7rem", paddingRight:"7rem" }} />
                {/* {this.getMissionVision()} */}
            </div>,
            <div
                id="services_stander"
                key="services_stander"
                style={{
                    height: "auto",
                    width: "100%",
                    background: "#fafafa",
                    // paddingBottom: "80px",
                    paddingTop: "8rem",
                }}
            >
                <CSCTitle
                    title="发展历程"
                    key="services_stander"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        marginBottom: "20px",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                        display: "inline-block",
                        borderBottom: "6px solid #2FB467",
                        paddingBottom:"0.6rem",
                    }}
                />
                <img src={require("../../assets/history_bg.png")} alt=""  style={{ width: "100%", }} />

                {/* {this.getDevelopmentHistory()}, */}
            </div>,
            <div
                id="contactus"
                key="contactus"
                style={{
                    height: "auto",
                    width: "100%",
                    background: "#fafafa",
                    paddingBottom: "60px",
                    paddingTop: "70px",
                    // display:"flex",
                    // alignItems:"center",
                    // justifyContent:"center"
                }}
            >
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <CSCTitle
                        title="联系我们"
                        key="contactus"
                        isMobile={this.state.isMobile}
                        style={{
                            margin: 0,
                            height: "auto",
                            
                        }}
                        textSty={{
                            fontSize: this.state.isMobile ? "20px" : "28px",
                            fontWeight: "700",
                        }}
                    />
                    <div style={{width:"8rem", height:"6px", background:"#2FB467",marginTop:"0.6rem" }}></div>
                </div>
                {/* 超云数字技术集团有限公司 */}
                {this.getContactUs()}
            </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
                style={{
                    // background:"rgba(255, 255, 255, 0.7)",
                     background:"rgb(248, 248, 248)",
                     width:"100%", height:"100%"}}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                <CSCBackTop
                    isMobile={this.state.isMobile}
                    key="company_intro"
                />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
