import React from 'react'
export const Footer10DataSource = {
    wrapper: {className: 'home-page-wrapper footer1-wrapper'},
    OverPack: {className: 'footer1', playScale: 0.2},
    block: {
        className: 'home-page',
        gutter: 0,
        children: [
            {
                name: 'block1',
                xs: 24,
                md: 5,
                className: 'block',
                title: {children: '关于超云'},
                childWrapper: {
                    children: [
                        {
                            name: 'link0',
                            href: '/about/company/intro',
                            children: '公司介绍',
                        },
                        {
                            name: 'link1',
                            href: '/about/company/intro/#contactus',
                            children: '联系我们',
                        },
                        {
                            name: 'link2',
                            href: '/about/recruit/society',
                            children: '加入我们',
                        },
                    ],
                },
            },
            {
                name: 'block1',
                xs: 24,
                md: 5,
                className: 'block',
                title: {children: '公司动态'},
                childWrapper: {
                    children: [
                        {
                            name: 'link0',
                            href: '/about/news/list',
                            children: '新闻动态',
                        },
                        {name: 'link1', href: '/', children: '首页动态'},
                        // { name: "link2", href: "#", children: "快速入门" },
                        // { name: "link3", href: "#", children: "参考指南" },
                    ],
                },
            },
            {
                name: 'block3',
                xs: 24,
                md: 5,
                className: 'block',
                title: {children: '超云产品'},
                childWrapper: {
                    children: [
                        {
                            href: '/product?p=2',
                            name: 'link1',
                            children: '信创服务器',
                        },
                        {
                            href: '/product/?p=1',
                            name: 'link1',
                            children: '智算服务器',
                        },
                        {
                            href: '/product?p=6',
                            name: 'link1',
                            children: '集中式存储',
                        },
                        {
                            href: '/product?p=7',
                            name: 'link1',
                            children: '分布式存储',
                        },
                        {
                            href: '/product?p=8',
                            name: 'link1',
                            children: '云平台 / 虚拟化',
                        },
                    ],
                },
            },
            {
                name: 'block4',
                xs: 24,
                md: 5,
                className: 'block',
                title: {children: '支持与服务'},
                childWrapper: {
                    children: [
                        {
                            href: '/techsupport/user/manual',
                            name: 'link0',
                            children: '用户手册',
                        },
                        {
                            href: '/techsupport/drive/download',
                            name: 'link1',
                            children: '驱动下载',
                        },
                        {
                            href: '/techsupport/infomation/safety',
                            name: 'link1',
                            children: '信息安全',
                        },
                    ],
                },
            },
            {
                name: 'block0',
                xs: 24,
                md: 4,
                className: 'block',
                title: {
                    className: 'logo',
                    // children: require("../assets/white_logo_bg.png"),
                    children: require('../assets/1.png'),

                    // children:
                    // "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.opendatacenter.cn%2Fupload%2F20210330%2Fcb435fc8420f45d590caa183a24e8119.jpg&refer=http%3A%2F%2Fwww.opendatacenter.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658998803&t=0c5a63cb62b848e6dbf44d9028e98504",
                },
                childWrapper: {
                    className: 'slogan',
                    children: [
                        {
                            name: 'content0',
                            children: '',
                        },
                    ],
                },
            },
        ],
    },
    copyrightWrapper: {className: 'copyright-wrapper'},
    copyrightPage: {className: 'home-page'},
    copyright: {
        className: 'copyright',
        children: (
            <span>
                <span style={{marginRight: '20px'}}>
                    版权所有 © 2010-2020 超云数字技术集团有限公司 保留一切权利
                </span>
                <span style={{}}>
                    <a
                        style={{color: '#999'}}
                        target="_blank"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502003364"
                    >
                        京公网安备 11011502003364号
                    </a>
                    <span> | 京ICP备 18042039号</span>
                </span>
            </span>
        ),
    },
}
