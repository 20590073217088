import React from "react";

export const Banner10DataSource = {
    wrapper: { className: "banner1" },
    BannerAnim: {
        children: [
            {
                name: "elem0",
                BannerElement: { className: "banner-user-elem" },
                textWrapper: { className: "banner1-text-wrapper" },
                bg: { className: "bg bg0" },
                title: {
                    className: "banner1-title",
                    children:
                        "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
                },
                content: {
                    className: "banner1-content",
                    children: "一个高效的页面动画解决方案",
                },
                button: { className: "banner1-button", children: "Learn More" },
            },
            {
                name: "elem1",
                BannerElement: { className: "banner-user-elem" },
                textWrapper: { className: "banner1-text-wrapper" },
                bg: { className: "bg bg1" },
                title: {
                    className: "banner1-title",
                    children:
                        "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
                },
                content: {
                    className: "banner1-content",
                    children: "一个高效的页面动画解决方案",
                },
                button: { className: "banner1-button", children: "Learn More" },
            },
            {
                name: "elem2",
                BannerElement: { className: "banner-user-elem" },
                textWrapper: { className: "banner1-text-wrapper" },
                bg: { className: "bg bg1" },
                title: {
                    className: "banner1-title",
                    children:
                        "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
                },
                content: {
                    className: "banner1-content",
                    children: "一个高效的页面动画解决方案",
                },
                button: { className: "banner1-button", children: "Learn More" },
            },
        ],
    },
};
export const Content00DataSource = {
    wrapper: { className: "home-page-wrapper content0-wrapper" },
    page: { className: "home-page content0" },
    OverPack: { playScale: 0.3, className: "" },
    titleWrapper: {
        className: "title-wrapper",
        children: [{ name: "title", children: "新闻动态" }],
    },
    childWrapper: {
        className: "content0-block-wrapper",
        children: [],
    },
};
// export const Content00DataSource = {
//     wrapper: { className: "home-page-wrapper content0-wrapper" },
//     page: { className: "home-page content0" },
//     OverPack: { playScale: 0.3, className: "" },
//     titleWrapper: {
//         className: "title-wrapper",
//         children: [{ name: "title", children: "产品与服务" }],
//     },
//     block: {
//         className: "block-wrapper",
//         children: [
//             {
//                 name: "block0",
//                 className: "block",
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     icon: {
//                         className: "icon",
//                         children:
//                             "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
//                     },
//                     title: { children: "一站式业务接入" },
//                     content: { children: "支付、结算、核算接入产品效率翻四倍" },
//                 },
//             },
//             {
//                 name: "block1",
//                 className: "block",
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     icon: {
//                         className: "icon",
//                         children:
//                             "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
//                     },
//                     title: { children: "一站式事中风险监控" },
//                     content: {
//                         children:
//                             "在所有需求配置环节事前风险控制和质量控制能力",
//                     },
//                 },
//             },
//             {
//                 name: "block2",
//                 className: "block",
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     icon: {
//                         className: "icon",
//                         children:
//                             "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
//                     },
//                     title: { children: "一站式数据运营" },
//                     content: {
//                         children: "沉淀产品接入效率和运营小二工作效率数据",
//                     },
//                 },
//             },
//             {
//                 name: "block3",
//                 className: "block",
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     icon: {
//                         className: "icon",
//                         children:
//                             "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
//                     },
//                     title: { children: "一站式数据运营" },
//                     content: {
//                         children: "沉淀产品接入效率和运营小二工作效率数据",
//                     },
//                 },
//             },
//         ],
//     },
// };
export const Content70DataSource = {
    wrapper: { className: "home-page-wrapper content7-wrapper" },
    page: { className: "home-page content7" },
    OverPack: {},
    titleWrapper: {
        className: "title-wrapper",
        children: [
            {
                name: "title",
                children: "蚂蚁金融云提供专业的服务",
                className: "title-h1",
            },
            { name: "content", children: "基于阿里云计算强大的基础资源" },
        ],
    },
    tabsWrapper: { className: "content7-tabs-wrapper" },
    block: {
        children: [
            {
                name: "block0",
                tag: {
                    className: "content7-tag",
                    text: { children: "PHONE", className: "content7-tag-name" },
                    icon: { children: "mobile" },
                },
                content: {
                    className: "content7-content",
                    text: {
                        className: "content7-text",
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>技术</h3>
                                <p>
                                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                                </p>
                                <br />
                                <h3>融合</h3>
                                <p>
                                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                                </p>
                                <br />
                                <h3>开放</h3>
                                符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                            </span>
                        ),
                    },
                    img: {
                        className: "content7-img",
                        children:
                            "https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png",
                        md: 10,
                        xs: 24,
                    },
                },
            },
            {
                name: "block1",
                tag: {
                    className: "content7-tag",
                    icon: { children: "tablet" },
                    text: {
                        className: "content7-tag-name",
                        children: "TABLET",
                    },
                },
                content: {
                    className: "content7-content",
                    text: {
                        className: "content7-text",
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>技术</h3>
                                <p>
                                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                                </p>
                                <br />
                                <h3>融合</h3>
                                <p>
                                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                                </p>
                                <br />
                                <h3>开放</h3>
                                符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                            </span>
                        ),
                    },
                    img: {
                        className: "content7-img",
                        md: 10,
                        xs: 24,
                        children:
                            "https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png",
                    },
                },
            },
            {
                name: "block2",
                tag: {
                    className: "content7-tag",
                    text: {
                        children: "DESKTOP",
                        className: "content7-tag-name",
                    },
                    icon: { children: "laptop" },
                },
                content: {
                    className: "content7-content",
                    text: {
                        className: "content7-text",
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>技术</h3>
                                <p>
                                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                                </p>
                                <br />
                                <h3>融合</h3>
                                <p>
                                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                                </p>
                                <br />
                                <h3>开放</h3>
                                符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                            </span>
                        ),
                    },
                    img: {
                        className: "content7-img",
                        md: 10,
                        xs: 24,
                        children:
                            "https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png",
                    },
                },
            },
        ],
    },
};

export const Content50DataSource = {
    wrapper: { className: "home-page-wrapper content5-wrapper" },
    page: { className: "home-page content5" },
    OverPack: { playScale: 0.3, className: "" },
    titleWrapper: {
        className: "title-wrapper",
        children: [
            { name: "title", children: "通用服务器", className: "title-h1" },
            {
                name: "content",
                className: "title-content",
                children: "",
            },
        ],
    },
    block: {
        className: "content5-img-wrapper",
        gutter: 16,
        children: [
            {
                name: "block0",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block1",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block2",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block3",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block4",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children: "超云R5210 G12 111111",
                    },
                },
            },
            {
                name: "block5",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block6",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block7",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block8",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block9",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },

            {
                name: "block10",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block11",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block12",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block13",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block14",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block15",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block16",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block17",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block18",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block19",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block20",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block21",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block22",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
        ],
    },
};
