/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import {
    RightOutlined,
    LeftOutlined,
    PhoneFilled,
    PhoneTwoTone,
    PhoneOutlined,
    
} from "@ant-design/icons";

import { Carousel, BackTop, Form } from "antd";
import CSCFloatBox from "../widget/CSCFloatBox";
import CSCConsultingBtn from "../widget/CSCConsultingBtn";


// import Nav3 from './Nav3';
import Banner1 from "./Banner1";
import Content0 from "./Content0";
import Content1 from "./Content1";
import Content3 from "./Content3";
import Content12 from "./Content12";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature6 from "./Feature6";
import Feature4 from "./Feature4";
import Content5 from "./Content5";
import Feature5 from "./Feature5";
import Feature8 from "./Feature8";
import Pricing0 from "./Pricing0";
import Teams2 from "./Teams2";
import Teams4 from "./Teams4";
import Contact0 from "./Contact0";
import Footer1 from "./Footer1";
import Footer0 from "./Footer0";

/**
 * 新添加
 */
import ContentAdd0 from "./ContentAdd0";
import ContentAdd1 from "./ContentAdd1";
import CSCBackTop from "../widget/CSCBackTop";

import owRequest from "../libs/request";
import config from "../libs/config";

import {
    // Nav30DataSource,
    Banner10DataSource,
    Content00DataSource,
    Content10DataSource,
    Content30DataSource,
    Content120DataSource,
    Feature10DataSource,
    Feature20DataSource,
    Feature60DataSource,
    Feature40DataSource,
    Content50DataSource,
    Feature50DataSource,
    Feature80DataSource,
    Pricing00DataSource,
    Teams20DataSource,
    Teams40DataSource,
    Contact00DataSource,
    // Footer10DataSource,
    Footer00DataSource,
    ContentAdd00DataSource,
} from "./data.source";
import "./less/antMotionStyle.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { location = {} } = typeof window !== "undefined" ? window : {};
const contentStyle = {
    height: "400px",
    lineHeight: "160px",
    textAlign: "center",
};

export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.refs = {};
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            pic_url: config.pic_url,
            bannerData: [],
            newProductRet: [],
            loadFinished: false,
            customerRet: {
                wrapper: { className: "home-page-wrapper content5-wrapper" },
                page: { className: "home-page content5" },
                OverPack: { playScale: 0.3, className: "" },
                titleWrapper: {
                    className: "title-wrapper",
                    children: [
                        {
                            name: "title",
                            children: "合作客户",
                            className: "title-h1",
                        },
                        {
                            name: "content",
                            className: "title-content",
                            children: "",
                        },
                    ],
                },
                block: {
                    className: "content5-img-wrapper",
                    gutter: 16,
                    children: [
                        // {
                        //     name: "block0",
                        //     className: "block",
                        //     span: 5,
                        //     children: {
                        //         wrapper: {
                        //             className: "content5-block-content",
                        //         },
                        //         img: {
                        //             children: "",
                        //         },
                        //         content: { children: "" },
                        //     },
                        // },
                    ],
                },
            },
            newsRet: {
                wrapper: { className: "home-page-wrapper content0-wrapper" },
                page: { className: "home-page content0" },
                OverPack: { playScale: 0.3, className: "" },
                titleWrapper: {
                    className: "title-wrapper",
                    children: [{ name: "title", children: "新闻动态" }],
                },
                childWrapper: {
                    className: "content0-block-wrapper",
                    children: [],
                },
            },
        };
    }

    // wrapper: { className: "banner1" },

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }

        owRequest
            .get("/office-website/index", {})
            .then((res) => {
                // console.log("res", res);
                if (res && res.code == 2000) {
                    let customers = [];

                    for (let i = 0; i < res.data.customerRet.length; i++) {
                        let item = res.data.customerRet[i];
                        customers.push({
                            name: `block${i}`,
                            className: "block",
                            span: 5,
                            children: {
                                wrapper: {
                                    className: "content5-block-content",
                                },
                                img: {
                                    children:
                                        config.pic_url + item.customer_logo,
                                },
                                content: { children: item.customer_name },
                            },
                        });
                    }

                    let news = [];
                    for (let i = 0; i < res.data.newsRet.length; i++) {
                        let item = res.data.newsRet[i];
                        news.push({
                            name: `block${i}`,
                            className: "content0-block",
                            md: 8,
                            xs: 24,
                            children: {
                                className: "content0-block-item",
                                children: [
                                    {
                                        name: "image",
                                        className: "content0-block-icon",
                                        children:
                                            config.pic_url + item.cover_image,
                                    },
                                    {
                                        name: "title",
                                        className: "content0-block-title",
                                        children: item.news_name,
                                        link: `${item.news_link}`,
                                    },
                                    {
                                        name: "content",
                                        children: `${item.news_source}  |  ${
                                            item.public_time
                                                ? item.public_time.slice(0, 10)
                                                : ""
                                        }`,
                                    },
                                    // {
                                    //     name: "content",
                                    //     children: `${item.news_link}`,
                                    // },
                                ],
                            },
                        });
                    }

                    this.state.customerRet.block.children = customers;
                    this.state.newsRet.childWrapper.children = news;

                    this.setState({
                        bannerData: res.data.bannerRet,
                        customerRet: this.state.customerRet,
                        newsRet: this.state.newsRet,
                        newProductRet: res.data.newProductRet,
                        loadFinished: true,
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                this.setState({
                    loadFinished: true,
                });
            });
        window.scroll(0, 0);

        /* 如果不是 dva 2.0 请删除 end */
    }

    onClickArrow(code) {
        // console.log("code", code);
        if (code == 1) {
            this.refs["HomeBanner"].prev();
        }
        if (code == 2) {
            this.refs["HomeBanner"].next();
        }
    }

    render() {
        if (!this.state.loadFinished) {
            return null;
        }
        const children = [
            <div style={{ position: "relative", cursor: "pointer" }}>
                {this.state.isMobile ? null : (
                    // <LeftOutlined
                    //     onClick={() => {
                    //         this.onClickArrow(1);
                    //     }}
                    //     style={{
                    //         position: "absolute",
                    //         zIndex: 100,
                    //         top: "50%",
                    //         left: "15px",
                    //         marginBottom: "-10px",
                    //         // marginB
                    //         width: isMobile ? "20px" : "100px",
                    //         height: isMobile ? "4px" : "50px",
                    //         fontSize: isMobile ? "18px" : "50px",
                    //         color: "#fff",
                    //         fontWeight: "700",
                    //     }}
                    // />
                    <img
                        src={require("../assets/arrow_l.png")}
                        onClick={() => {
                            this.onClickArrow(1);
                        }}
                        style={{
                            position: "absolute",
                            zIndex: 100,
                            top: "50%",
                            left: "2.5rem",
                            marginTop: isMobile ? "-10px" : "-2rem",
                            width: isMobile ? "12px" : "2rem",
                            height: isMobile ? "20px" : "3.6rem",
                            fontSize: isMobile ? "18px" : "50px",
                            color: "#fff",
                            fontWeight: "700",
                        }}
                    />
                )}
                {this.state.isMobile ? null : (
                    // <RightOutlined
                    //     onClick={() => {
                    //         this.onClickArrow(2);
                    //     }}
                    //     style={{
                    //         position: "absolute",
                    //         zIndex: 100,
                    //         top: "50%",
                    //         right: "15px",
                    //         marginBottom: "-10px",
                    //         width: isMobile ? "20px" : "100px",
                    //         height: isMobile ? "4px" : "50px",
                    //         fontSize: isMobile ? "18px" : "50px",
                    //         color: "#fff",
                    //         fontWeight: "700",
                    //     }}
                    // />
                    <img
                        src={require("../assets/arrow_r.png")}
                        onClick={() => {
                            this.onClickArrow(2);
                        }}
                        style={{
                            position: "absolute",
                            zIndex: 100,
                            top: "50%",
                            right: "2.5rem",
                            marginTop: isMobile ? "-10px" : "-2rem",
                            width: isMobile ? "12px" : "2rem",
                            height: isMobile ? "20px" : "3.6rem",
                            fontSize: isMobile ? "18px" : "50px",
                            color: "#fff",
                            fontWeight: "700",
                        }}
                    />
                )}
                <Carousel
                    effect="fade"
                    ref="HomeBanner"
                    autoplay
                    id="Banner1_0"
                    key="Banner1_0"
                    autoplaySpeed={6000}
                    // dots={"dotClass"}
                >
                    {this.state.bannerData.map((item) => {
                        return (
                            <div
                                key={item.banner_image_url}
                                style={{
                                    width: "100%",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (item.banner_link_url) {
                                        window.location.href =
                                            item.banner_link_url;
                                    }
                                }}
                            >
                                <img
                                    style={{
                                        width: "100%",
                                    }}
                                    src={
                                        this.state.pic_url +
                                        item.banner_image_url
                                    }
                                    alt=""
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </div>,
            // <Banner1
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={this.state.bannerData}
            //     isMobile={this.state.isMobile}
            // />,
            <ContentAdd0
                id="ContentAdd_0"
                key="ContentAdd_0"
                dataSource={ContentAdd00DataSource}
                newProductRet={this.state.newProductRet}
                isMobile={this.state.isMobile}
            />,
            <div style={{ width: "100%", paddingBottom: "6rem" }}>
                <ContentAdd1
                    id="Content1_0"
                    key="Content1_0"
                    dataSource={Content10DataSource}
                    isMobile={this.state.isMobile}
                />
            </div>,
            <div
                style={{
                    width: "100%",
                    // paddingBottom: "6rem",
                    background: "#fff",
                }}
            >
                <div style={{ width: "100%" }}>
                    <img
                        style={{ width: "100%" }}
                        src={require("../assets/customer_logos.png")}
                    />
                </div>
            </div>,
            // <div
            //     style={{
            //         width: "100%",
            //         // paddingBottom: "6rem",
            //         background: "#fff",
            //     }}
            // >
            //     <Content5
            //         id="Content5_0"
            //         key="Content5_0"
            //         // dataSource={Content50DataSource}
            //         dataSource={this.state.customerRet}
            //         isMobile={this.state.isMobile}
            //     />
            // </div>,
            // <div style={{ width: "100%", paddingBottom: "6rem" }}>
            <Content0
                id="Content0_0"
                key="Content0_0"
                // dataSource={Content00DataSource}、
                dataSource={this.state.newsRet}
                isMobile={this.state.isMobile}
            />,
            // </div>,
            // <Content1
            //     id="Content1_0"
            //     key="Content1_0"
            //     dataSource={Content10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content3
            //     id="Content3_0"
            //     key="Content3_0"
            //     dataSource={Content30DataSource}
            //     isMobile={this.state.isMobile}
            // />,

            // <Content12
            //     id="Content12_0"
            //     key="Content12_0"
            //     dataSource={Content120DataSource}
            //     isMobile={this.state.isMobile}
            // />,

            // <Feature1
            //     id="Feature1_0"
            //     key="Feature1_0"
            //     dataSource={Feature10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Feature2
            //     id="Feature2_0"
            //     key="Feature2_0"
            //     dataSource={Feature20DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Feature6
            //     id="Feature6_0"
            //     key="Feature6_0"
            //     dataSource={Feature60DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Feature4
            //     id="Feature4_0"
            //     key="Feature4_0"
            //     dataSource={Feature40DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content5
            //     id="Content5_0"
            //     key="Content5_0"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Feature5
            //     id="Feature5_0"
            //     key="Feature5_0"
            //     dataSource={Feature50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Feature8
            //     id="Feature8_0"
            //     key="Feature8_0"
            //     dataSource={Feature80DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Pricing0
            //   id="Pricing0_0"
            //   key="Pricing0_0"
            //   dataSource={Pricing00DataSource}
            //   isMobile={this.state.isMobile}
            // />,
            // <Teams2
            //   id="Teams2_0"
            //   key="Teams2_0"
            //   dataSource={Teams20DataSource}
            //   isMobile={this.state.isMobile}
            // />,
            // <Teams4
            //   id="Teams4_0"
            //   key="Teams4_0"
            //   dataSource={Teams40DataSource}
            //   isMobile={this.state.isMobile}
            // />,
            // <Contact0
            //   id="Contact0_0"
            //   key="Contact0_0"
            //   dataSource={Contact00DataSource}
            //   isMobile={this.state.isMobile}
            // />,

            // <Footer0
            //   id="Footer0_0"
            //   key="Footer0_0"
            //   dataSource={Footer00DataSource}
            //   isMobile={this.state.isMobile}
            // />, // 导航和页尾不进入锚点区，如果需要，自行添加;
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* <CSCFloatBox isMobile={this.state.isMobile} /> */}
                <CSCBackTop isMobile={this.state.isMobile} />
                {/* <CSCConsultingBtn isMobile={this.state.isMobile} /> */}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
