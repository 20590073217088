/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../widget/CSCTitle";
// import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col, Anchor, BackTop } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import Content0 from "./Content0";
import Banner1 from "./Banner1";

import Content7 from "./Content7";
import Banner from "../Banner/index";
import CSCButton from "../widget/CSCButton";
import CSCBackTop from "../widget/CSCBackTop";

import Content5 from "./Content5";
import { TweenOneGroup } from "rc-tween-one";
import owRequest from "../libs/request";
import config from "../libs/config";

import {
    Content00DataSource,
    Content70DataSource,
    Banner10DataSource,
    Content50DataSource,
} from "./data.source.js";

import "./less/antMotionStyle.less";
import "./less/solutionDetail.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { Link } = Anchor;

const location = window.location;

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            solutionDetailObj: {},
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        let url = window.location.href;
        let sId = url.split("?solutionid=")[1];
        // console.log("sId", sId);
        this.sId = sId;
        this.getSolutionDetail();
    }
    handleAnchorClick = (e, link) => {
        e.preventDefault();
    };

    // getProductItem() {
    //     return (
    //         <div className="product_item">
    //             <div className="product_img">
    //                 <img
    //                     src="https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg"
    //                     alt=""
    //                 />
    //             </div>
    //             <div className="product_title">233333</div>
    //             <div className="product_desc">444</div>
    //         </div>
    //     );
    // }

    getSolutionDetail() {
        owRequest
            .get(`/office-website/solution?id=${this.sId}`)
            .then((res) => {
                if (res && res.code == 2000) {
                    let ret = {};
                    if (res.data.rows && res.data.rows.length) {
                        ret = res.data.rows[0];
                    }

                    this.setState({ solutionDetailObj: ret || {} });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    onClickTabItem(activeTab) {
        this.setState({ activeTab: activeTab });
    }

    render() {
        const children = [
            // <Anchor
            //     affix={false}
            //     showInkInFixed={false}
            //     onClick={(e) => {
            //         this.handleAnchorClick(e);
            //     }}
            // >
            //     <Link href="#project_bg" title="项目背景" />
            //     <Link href="#requirement_analysis" title="需求分析" />
            //     <Link href="#solution" title="解决方案" />
            //     <Link href="#customer_benefits" title="客户收益" />
            // </Anchor>,
            // <Banner1
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <div
                style={{
                    position: "relative",
                }}
            >
                <img
                    src={require("../assets/solution_bg.png")}
                    alt=""
                    style={{ width: "100%" }}
                />
                <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        top: this.state.isMobile ? "3rem" : "35%",
                        left: this.state.isMobile ? "1rem" : "10%",
                        width: "30rem",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    {this.state.solutionDetailObj.solution_name || ""}
                </div>
            </div>,

            <Content0
                id="project_bg"
                key="project_bg"
                dataSource={Content00DataSource}
                isMobile={this.state.isMobile}
                solutionDetailObj={this.state.solutionDetailObj || {}}
                contentTxt={this.state.solutionDetailObj.project_bg || ""}
                content0title={"项目背景"}
            />,
            <Content0
                id="requirement_analysis"
                key="requirement_analysis"
                dataSource={Content00DataSource}
                isMobile={this.state.isMobile}
                solutionDetailObj={this.state.solutionDetailObj || {}}
                contentTxt={
                    this.state.solutionDetailObj.requirement_analysis || ""
                }
                content0title={"需求分析"}
            />,
            <Content0
                id="solution"
                key="solution"
                dataSource={Content00DataSource}
                isMobile={this.state.isMobile}
                solutionDetailObj={this.state.solutionDetailObj || {}}
                contentTxt={this.state.solutionDetailObj.solution_plan || ""}
                content0title={"解决方案"}
            />,

            <Content0
                id="customer_benefits"
                key="customer_benefits"
                dataSource={Content00DataSource}
                isMobile={this.state.isMobile}
                solutionDetailObj={this.state.solutionDetailObj || {}}
                content0title={"客户收益"}
                contentTxt={
                    this.state.solutionDetailObj.customer_benefits || ""
                }
            />,
            // <div id="custom_profit"></div>,
            // </div>,
            // <Content5
            //     id="Content5_0"
            //     key="Content5_0"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content7
            //     id="Content7_0"
            //     key="Content7_0"
            //     dataSource={Content70DataSource}
            //     isMobile={this.state.isMobile}
            // />,
        ];
        return (
            <div
                className="templates-wrapper"
                style={{
                    background: "#f9f9f9",
                    backgroundColor: "#f9f9f9",
                    position: "relative",
                    // paddingTop: "10px",
                }}
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                <OverPack playScale={0.3}>
                    <TweenOne
                        type="bottom"
                        key="block"
                        leaveReverse
                        forcedReplay={false}

                        // {...dataSource.block}
                        // component={Row}
                    >
                        {this.state.show && children}
                    </TweenOne>
                </OverPack>
                ,{/* 如果不是 dva 2.0 替换成 {children} end */}
                <CSCBackTop
                    isMobile={this.state.isMobile}
                    key="techsupport_backTop"
                />
            </div>
        );
    }
}

const style = React.cree;
