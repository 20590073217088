/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { Pagination, BackTop } from "antd";
import CSCTitle from "../../widget/CSCTitle";
import CSCBackTop from "../../widget/CSCBackTop";

import Content0 from "./Content0";
import owRequest from "../../libs/request";
import config from "../../libs/config";

import "./less/antMotionStyle.less";
// import "./less/product.less";

import {
    // Nav30DataSource,
    Banner10DataSource,
    Content00DataSource,
    Content10DataSource,
    Content30DataSource,
    Content120DataSource,
    Feature10DataSource,
    Feature20DataSource,
    Feature60DataSource,
    Feature40DataSource,
    Content50DataSource,
    Feature50DataSource,
    Feature80DataSource,
    Pricing00DataSource,
    Teams20DataSource,
    Teams40DataSource,
    Contact00DataSource,
    // Footer10DataSource,
    Footer00DataSource,
    ContentAdd00DataSource,
} from "./data.source";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "1080px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            newList: [],
            current: 1,
            currentPageSize: 10,
            totalPage: 0,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        document.documentElement.scrollTop = 0; //ie下
        document.body.scrollTop = 0; //非ie
        /* 如果不是 dva 2.0 请删除 end */
        this.getNewsList();
    }

    getNewsList() {
        owRequest
            .get(
                `/office-website/news?page=${this.state.current}&page_size=${this.state.currentPageSize}`
            )
            .then((res) => {
                // console.log("res____", res);
                if (res && res.code == 2000) {
                    let ret = [];
                    ret = res.data.rows;
                    this.setState({
                        newList: ret || [],
                        totalPage: res.data.count,
                    },()=>{
                    const event = new CustomEvent('customEvent', { detail: 'Hello from React!' });
                      document.dispatchEvent(event);
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        let anchorElement = document.getElementById(id);
       
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    getHeadNewsItem() {
        return (
            <div
                style={{
                    width: this.state.isMobile ? "100%" : "100%",
                    // background: "yellow",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    marginBottom: "40px",
                    transition: "all 1s linear",
                    // padding: "15px",
                }}
            >
                <div style={{ flex: 1, padding: "20px" }}>
                    <img
                        src={config.pic_url + item.cover_image}
                        alt=""
                        // style={{ width: "8rem", height: "9rem" }}
                        style={{
                            width: "100%",
                            marginTop: "10px",
                        }}
                    />
                </div>
                <div style={{ flex: 2, padding: "10px" }}>
                    <div
                        style={{
                            fontWeight: 600,
                            fontSize: "1.3rem",
                            marginBottom: "10px",
                        }}
                    >
                        深耕ISV领域 | 超云携手AMD释放行业计算力价值
                    </div>
                    <div
                        style={{
                            fontSize: "0.8rem",
                            // marginBottom: "20px",
                        }}
                    >
                        新闻来源 | 2022-04-11
                    </div>
                </div>
            </div>
        );
    }

    getNewsItem(item) {
        return (
            <div
                style={{
                    width: this.state.isMobile ? "100%" : "100%",
                    display: "flex",
                    flexDirection: this.state.isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    marginBottom: "40px",
                    transition: "all 1s linear",
                }}
            >
                <div style={{ flex: 1, padding: "20px" }}>
                    <img
                        src={config.pic_url + item.cover_image}
                        alt={item.news_name}
                        onClick={() => {
                            this.onClickNewsItem(item);
                        }}
                        // style={{ width: "8rem", height: "9rem" }}
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            cursor: "pointer",
                        }}
                    />
                </div>
                <div style={{ flex: 2, padding: "25px" }}>
                    <div
                        style={{
                            fontWeight: 600,
                            fontSize: "1.3rem",
                            marginBottom: "20px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            this.onClickNewsItem(item);
                        }}
                    >
                        {item.news_name || ""}
                    </div>
                    <div
                        style={{
                            fontSize: "1rem",
                            marginBottom: "15px",
                            minHeight: "110px",
                        }}
                    >
                        {item.news_desc && item.news_desc.length
                            ? item.news_desc.length > 140
                                ? item.news_desc.slice(0, 140) + "..."
                                : item.news_desc
                            : ""}
                        <a
                            onClick={() => {
                                this.onClickNewsItem(item);
                            }}
                        >
                            详情
                        </a>
                    </div>
                    <div
                        style={{
                            fontSize: "0.8rem",
                            // marginBottom: "20px",
                        }}
                    >
                        {item.news_source || ""} |{" "}
                        {item.public_time && item.public_time.length > 0
                            ? item.public_time.slice(0, 10)
                            : null}
                    </div>
                </div>
            </div>
        );
    }
    onChangePage = (e) => {
        console.log("e", e);
        this.setState({ current: e }, () => {
            // window.scrollTo({ x: 0, y: 0 }, { behavior: "smooth" });
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            this.getNewsList();
        });
    };
    onClickNewsItem(item) {
        // console.log("onClickNewsItem", item);
        window.open(item.news_link);
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <div>
                {!this.state.isMobile ? (
                    <img
                        src={require("../../assets/head_news.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../../assets/head_news.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
            </div>,
            <div
                style={{
                    ...textSty,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "1100px",
                }}
            >
                {this.state.newList.map((item) => {
                    return this.getNewsItem(item);
                })}
            </div>,
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    paddingTop: "60px",
                    paddingBottom: "120px",
                }}
            >
                {/* {this.state.totalPage} */}
                <Pagination
                    current={this.state.current}
                    onChange={this.onChangePage}
                    total={this.state.totalPage}
                    pageSize={this.state.currentPageSize}
                    // showTotal={true}
                />
            </div>,
            // <div
            //     className="p_tab_box_51_wrapper"
            //     key={"p_tab_box_51_wrapper"}
            //     style={{
            //         paddingTop: this.state.isMobile ? "30px" : "60px",
            //         paddingBottom: this.state.isMobile ? "10px" : "60px",
            //     }}
            // >
            //     <div className="p_tab_box_51">
            //         {[
            //             { title: "服务范围", href: "services_scope" },
            //             { title: "服务种类", href: "services_type" },
            //             { title: "服务标准", href: "services_stander" },
            //             { title: "其它说明", href: "other_tips" },
            //         ].map((item, index) => {
            //             console.log("item", item);
            //             return (
            //                 <a
            //                     // onClick = {()=>{}}
            //                     // href={`#${item.href}`}
            //                     key={"cb" + index}
            //                     className={
            //                         this.state.activeTab == index
            //                             ? "p_tab_item_51_active p_tab_item_51"
            //                             : "p_tab_item_51"
            //                     }
            //                     onClick={() => {
            //                         this.onClickTabItem(index, item.href);
            //                     }}
            //                 >
            //                     {item.title}
            //                 </a>
            //             );
            //         })}
            //     </div>
            // </div>,
            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     {this.getTitleDes()},
            // </div>,

            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务范围"
            //         key="services_scope"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceScope()},
            // </div>,
            // <div
            //     id="services_type"
            //     key="services_type"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务种类"
            //         key="services_type"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceTypes()},
            // </div>,
            // <div
            //     id="services_stander"
            //     key="services_stander"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务标准"
            //         key="services_stander"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServicesStander()},
            // </div>,

            // <div
            //     id="other_tips"
            //     key="other_tips"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="其它说明"
            //         key="other_tips"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getOtherTips()}
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                <CSCBackTop isMobile={this.state.isMobile} key="news_list" />

                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
