import React from "react";
// import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";
import "./less/content0.less";
import CSCDecorateLine from "../widget/CSCDecorateLine";

class NewsList extends React.PureComponent {
    onClickNewsItem = (item) => {
        if (!item) {
            return;
        }
        // console.log("123___onClickNewsItem", item);
        let url = item.children[1].link;
        // console.log(item.children[1].link);
        if (url) {
            window.open(url);
        }
    };
    render() {
        const { dataSource, isMobile, ...props } = this.props;
        const {
            wrapper,
            titleWrapper,
            page,
            OverPack: overPackData,
            childWrapper = {},
        } = dataSource;
        const newsDataList = childWrapper.children || [];
        return (
            <div {...props} {...wrapper}>
                <div {...page}>
                    <div {...titleWrapper}>
                        <CSCDecorateLine
                            children={titleWrapper.children.map(
                                getChildrenToRender
                            )}
                            isMobile={isMobile}
                        />
                        {/* {titleWrapper.children.map(getChildrenToRender)} */}
                    </div>
                    {!isMobile && (
                        <div
                            style={{
                                width: "100%",
                                height: "36.32rem",
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    background:
                                        "url(" +
                                        require("../assets/news_bg_1.png") +
                                        ") ",
                                    flex: 1,
                                    backgroundSize: "100% 100%",
                                    backgroundRepeat: "no-repeat",
                                    height: "36.32rem",
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        width: "88%",
                                        marginLeft: "3rem",
                                        marginTop: "5rem",
                                        color: "#fff",
                                        fontWeight: 600,
                                        fontSize: "2.6rem",
                                    }}
                                >
                                    {newsDataList[0]
                                        ? newsDataList[0].children.children[1]
                                              .children
                                        : ""}
                                </div>
                                <div
                                    style={{
                                        width: "87%",
                                        marginLeft: "3rem",
                                        marginTop: "3rem",
                                        color: "#fff",
                                        fontSize: "1.3rem",
                                    }}
                                >
                                    {newsDataList[0]
                                        ? newsDataList[0].children.children[2]
                                              .children
                                        : ""}
                                </div>
                                <div
                                    style={{
                                        marginLeft: "3rem",
                                        marginTop: "2rem",
                                        color: "#03E79A",
                                        fontSize: "1rem",
                                        position: "absolute",
                                        bottom: "2rem",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.onClickNewsItem(
                                            newsDataList[0]
                                                ? newsDataList[0].children
                                                : ""
                                        );
                                    }}
                                >
                                    查看详情＞＞
                                </div>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    height: "36.32rem",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        // height: "36.32rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            background:
                                                "url(" +
                                                require("../assets/news_bg_3.png") +
                                                ") ",
                                            flex: 1,
                                            backgroundSize: "100% 100%",
                                            backgroundRepeat: "no-repeat",

                                            flex: 1,
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            flex: 1,
                                            background: "#342072",
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "88%",
                                                marginLeft: "1.5rem",
                                                marginTop: "2rem",
                                                color: "#fff",
                                                fontWeight: 600,
                                                fontSize: "1.4rem",
                                            }}
                                        >
                                            {newsDataList[1]
                                                ? newsDataList[1].children
                                                      .children[1].children
                                                : ""}
                                        </div>
                                        <div
                                            style={{
                                                width: "88%",
                                                marginLeft: "1.5rem",
                                                marginTop: "2rem",
                                                color: "#fff",
                                                fontSize: "0.7rem",
                                            }}
                                        >
                                            {newsDataList[1]
                                                ? newsDataList[1].children
                                                      .children[2].children
                                                : ""}
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: "1.5rem",
                                                marginTop: "2rem",
                                                color: "#03E79A",
                                                fontSize: "1rem",
                                                position: "absolute",
                                                bottom: "2rem",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                this.onClickNewsItem(
                                                    newsDataList[1]
                                                        ? newsDataList[1]
                                                              .children
                                                        : ""
                                                );
                                            }}
                                        >
                                            查看详情＞＞
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            background:
                                                "url(" +
                                                require("../assets/news_bg_2.png") +
                                                ") ",
                                            flex: 1,
                                            backgroundSize: "100% 100%",
                                            backgroundRepeat: "no-repeat",
                                            flex: 1,
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            flex: 1,
                                            background: "#1067A4",
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "88%",
                                                marginLeft: "1.5rem",
                                                marginTop: "2rem",
                                                color: "#fff",
                                                fontWeight: 600,
                                                fontSize: "1.4rem",
                                            }}
                                        >
                                            {newsDataList[2]
                                                ? newsDataList[2].children
                                                      .children[1].children
                                                : ""}
                                        </div>
                                        <div
                                            style={{
                                                width: "88%",
                                                marginLeft: "1.5rem",
                                                marginTop: "2rem",
                                                color: "#fff",
                                                fontSize: "0.7rem",
                                            }}
                                        >
                                            {newsDataList[2]
                                                ? newsDataList[2].children
                                                      .children[2].children
                                                : ""}
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: "1.5rem",
                                                marginTop: "2rem",
                                                color: "#03E79A",
                                                fontSize: "1rem",
                                                position: "absolute",
                                                bottom: "2rem",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                this.onClickNewsItem(
                                                    newsDataList[2]
                                                        ? newsDataList[2]
                                                              .children
                                                        : ""
                                                );
                                            }}
                                        >
                                            查看详情＞＞
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isMobile && (
                        <div
                            style={{
                                width: "100%",
                                height: "4.8rem",
                                background:
                                    "linear-gradient(90deg, #00A7D1, #006CD1, #00AA71, #1AE3A0)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "1.1rem",
                                fontWeight: 700,
                                color: "#fff",
                                cursor: "pointer",
                                marginBottom: "2rem",
                            }}
                            onClick={() => {
                                window.open("/about/news/list");
                            }}
                        >
                            更多新闻
                        </div>
                    )}

                    {isMobile && (
                        <OverPack {...overPackData}>
                            <TweenOne
                                type="bottom"
                                key="block"
                                leaveReverse
                                component={Row}
                                componentProps={childWrapper}
                                       forcedReplay={false}
                            >
                                {childWrapper.children.map((block, i) => {
                                    const { children: item, ...blockProps } =
                                        block;
                                    return (
                                        <Col key={i.toString()} {...blockProps}>
                                            <div
                                                {...item}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    this.onClickNewsItem(item);
                                                }}
                                            >
                                                {item.children.map(
                                                    getChildrenToRender
                                                )}
                                            </div>
                                        </Col>
                                    );
                                })}
                            </TweenOne>
                            <div className="more_news_wrapper">
                                <span
                                    className="more_news_btn"
                                    onClick={() => {
                                        window.open("/about/news/list");
                                    }}
                                >
                                    更多新闻
                                </span>
                            </div>
                        </OverPack>
                    )}
                </div>
            </div>
        );
    }
}

export default NewsList;
